:root {
  --principal-color: black;
  --secondary-color: #d4db70;
  --background-color: #fffafa;
  --text-font: 'JetBrains Mono', monospace;
  --title-font: 'Source Code Pro', monospace;
  --logo-font: 'Russo One', sans-serif;
}

body {
  color: var(--principal-color);
  font-family: var(--text-font);
  background-color: var(--background-color);
}

a {
  color: var(--principal-color);
}
